export const strings = {
  en: {
    beamAttribution: () => `Powered by Beam`,
    ctaTitle: () => `Choose your impact`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Select a nonprofit and ${donationPercentage}% of your purchase will be donated, at no extra cost.`,
    inlineSeparator: () => `: `,
  },
  fr: {
    beamAttribution: () => `Optimisé par Beam`,
    ctaTitle: () => `Choisissez votre cause`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Choisissez un organisme à but non lucratif et ${donationPercentage} % lui sera versé en votre nom, sans frais supplémentaires.`,
    inlineSeparator: () => ` : `,
  },
  de: {
    beamAttribution: () => `Unterstützt von Beam`,
    ctaTitle: () => `Wähle deinen Impact`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Wähle eine der gemeinnützigen Organisationen und spende ${donationPercentage}% deines Einkaufs ohne zusätzliche Kosten`,
    inlineSeparator: () => `: `,
  },
};
